import React, { useEffect, useState } from 'react'
import '../lid.css'
import '../home.css'
import ReturnToMas from '../afterChack/ReturnToMas';
import { useNavigate, useParams } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import '../loading.css';
import CallBack from '../backNumber/callBack';
import AddCus from '../addMenger/addMenger';


export default function ShowLidim() {
    const allCategories = ["זכאי", "לא זכאי" , "השאירו פרטים" , "שבח"]
    const allCategoriesRon = ["זכאי" , "לא זכאי", "השאירו פרטים","שבח", "ניהול" ]
    const [Catgories, setCatgories] = useState(allCategories);
    const [CatgorIndex, setIndex] = useState(0);
    const { name } = useParams();
    const nav = useNavigate()
    useEffect(() => {
        if (name === "ron") {
            setCatgories(allCategoriesRon)

        } else {
            setCatgories(allCategories)
        }
    }, [])

    const backReashi = () => {
        nav(`/${name}`)

    }


    return (
        <div style={{ overflow: 'auto', height: 'inherit', display: 'block' }}>
            <div className='backColor'>
                <div className='align-baseline'>
                    <div className=' d-flex justify-content-between'>
                        <img className='mt-3 ms-3 animated-button' src="/imges/mini.png" height={40} alt="" />
                        <div className=' d-flex justify-content-end'>
                            <button onClick={backReashi} type="submit" className="btn btn-dark  me-3 mt-3 border-3 border-success animated-b"   ><FaHome size={25} />
                            </button>
                        </div>
                    </div>

                    <div className=' d-flex justify-content-center'>
                        <h2 className='animated-b title text-center text-white'>פאנל ניהול</h2>

                    </div>
                </div>
                <hr className='hr'></hr>
                <div className='btn-cat' style={{ direction: 'rtl' }}>
                    {
                        Catgories.map((category, i) => {
                            return (
                                <button type='button' style={{ backgroundColor: CatgorIndex === i ? 'rgb(0, 181, 152)' : 'rgb(48, 47, 47)' }} className='btn text-white me-1 ms-1' key={category} onClick={() => setIndex(i)} >
                                    {category}
                                </button>
                            )
                        })
                    }
                </div>
                <div className='' >
                    <div className=''>
                        <div className='  mt-2'>
                            {CatgorIndex === 0 ? <ReturnToMas zaki="זכאי" /> :
                            CatgorIndex === 1 ? <ReturnToMas zaki="דורש בדיקה"/> :
                            CatgorIndex === 3 ? <ReturnToMas zaki="שבח"/> :
                                CatgorIndex === 2 ? <CallBack/> :
                                    <AddCus/>}
                        </div>
                    </div>
                </div>

                <br />

            </div>

        </div>

    )
}





