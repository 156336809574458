import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppEnterPage from './HomePage/AppEnterPage'
import { initializeApp } from 'firebase/app';
import Login from './logIn/Login';
import './home.css'
import QuesPage from './chackMas/QuestionsPage';
import LoginRashi from './logIn/LoginRashi';
import PageRishmi from './HomePage/pageRishmi';
import { useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, getInstance, deleteUser } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCe5jsMPaxuVoa3NNC8QIwwxc8-G5Sk0Mw",
  authDomain: "ronmas-ec128.firebaseapp.com",
  databaseURL: "https://ronmas-ec128-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ronmas-ec128",
  storageBucket: "ronmas-ec128.appspot.com",
  messagingSenderId: "854834487002",
  appId: "1:854834487002:web:19622bfcab5f9754f7cfe3",
  measurementId: "G-LT6CEXCQTY"
};
const app = initializeApp(firebaseConfig);


function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginRashi />} />
          <Route path="/:dynamicSegment" element={<AppEnterPage />} />
          <Route path="/:name/login/" element={<Login />} />
          <Route path="/:name/calculator/" element={<QuesPage />} />
          <Route path="/:name/Shevah/" element={<QuesPage />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
