import React, { useEffect, useRef, useState } from 'react';
import '../home.css';
import '../loading.css';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import { FaFilePdf } from "react-icons/fa6";
import PDFJSWorker from '/node_modules/pdfjs-dist/legacy/build/pdf.worker.entry.js';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
pdfjs.GlobalWorkerOptions.workerSrc = PDFJSWorker



function TakePDF(props) {
  const location = useLocation();
  const pathname = location.pathname;
  let [sah158, setSah158] = useState(0);
  let [sah158Show, setSah158Show] = useState(0);
  let [cline042, setCline042] = useState(0);
  let [cline042Show, setCline042Show] = useState(0);
  let [working045, setWorking045] = useState(0);
  const [selectedOptionY, setSelectedOption] = useState("בחר שנה לבדיקה");
  let [fileNew, setFile] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleNumber, setIsVisibleNumber] = useState(false);
  let [sahAndCline, setAll] = useState([]);
  const [isVisibleFormHend, setFormError] = useState(false);
  const [isMessErrHend, setMessErrHend] = useState(false);
  const sah158Iput = useRef();
  const cline042Iput = useRef();
  const working045Iput = useRef();
  const [inputValueSah, setInputValueSah] = useState('');
  const [inputValueClien, setInputValueClien] = useState('');
  const [inputValueWorking, setInputValueWorking] = useState('');
  const [pdfDownloadUrl, setDownloadUrl] = useState([]);
  const [palgPDf, setPalgPDF] = useState(false);
  const storage = getStorage();
  const [inputValueName, setInputValue] = useState('');
  const [selectedZN, setSelectedZN] = useState("בחר זכר או נקבה");
  const [arryDataPdfRemove, setDataPdfRemove] = useState([]);
  const [arrUrlPdf, setUrlPdf] = useState([]);
  const [show045Input, setShow045Input] = useState(false);
  const [y18, sety18] = useState(true);
  const [y19, sety19] = useState(true);
  const [y20, sety20] = useState(true);
  const [y21, sety21] = useState(true);
  const [y22, sety22] = useState(true);
  const [y23, sety23] = useState(true);
  const addFile = (files, e) => {
    console.log("name1")
    const file = e.target.files[0];
    console.log("name", file.name)
    console.log("name1")
    if (file.type === 'application/pdf') {
      if (fileNew.length > 0) {
        fileNew = [...fileNew, ...files];
        checkPDF(files);
        setFile(fileNew);
        setPalgPDF(true);
      } else {
        setFile(files);
        checkPDF(files);
        setPalgPDF(true)
      }
    }
    else {
      alert('Please select a PDF file.');
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    // Remove any numeric characters from the input value
    const newValue = value.replace(/[0-9]/g, '');
    setInputValue(newValue);
  }

  const sendYearURl = () => {
    props.upadteYearPdfUrl(selectedOptionY, pdfDownloadUrl);
  }
  const sendURlPDF = (pdfDownload) => {
    props.upadteYearPdfUrl(pdfDownload);
  }


  const handleSelectYear = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if(pathname.includes("Shevah")){
      setSelectedOption("2020")
    }
    if (props.year != '') {
      setSelectedOption(props.year);
    }
    if (!props.ifCheckPartner) {
      for (let i = 0; i < 6; i++) {
        switch (props.UseYears[i]) {
          case '2018':
            sety18(false)
            break;
          case '2019':
            sety19(false)
            break;
          case '2020':
            sety20(false)
            break;
          case '2021':
            sety21(false)
            break;
          case '2022':
            sety22(false)
            break;
          case '2023':
            sety23(false)
            break;
          default:
            break;
        }

      }
    }


  }, [fileNew]);

  const updateData = (sah158, cline042, working045New, inputValueName, selectedZN) => {
    props.UpdateDataPDF(sah158, cline042, working045New, inputValueName, selectedZN);
    //sendSahClinHend(sah158, cline042)
  }


  const handleUpload = (pdfFile, text) => {
    const pdfRef = ref(storage, `pdfs/${pdfFile[0].name}`);
    if (pdfFile[0]) {
      const uploadTask = uploadBytesResumable(pdfRef, pdfFile[0]);
      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error(error);
        },
        () => {
          // Get download URL and add it to the database
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (pdfDownloadUrl.length > 0) {
              const newPdfDownloadUrl = pdfDownloadUrl.concat(downloadURL)
              //const newPdfDownloadUrl = [...pdfDownloadUrl, ...downloadURL];
              setDownloadUrl(newPdfDownloadUrl);
              console.log("aa", downloadURL);
              setPalgPDF(false);
              const arrUrlPdfTamp = arrUrlPdf.concat(downloadURL);
              setUrlPdf(arrUrlPdfTamp)
              sendURlPDF(newPdfDownloadUrl);
              if (text == "") {
                setFile([]);
                setFormError(true);
                setMessErrHend(true);
              }
            } else {
              const newPdfDownloadUrl = pdfDownloadUrl.concat(downloadURL)
              console.log("aa", downloadURL);
              const arrUrlPdfTamp = arrUrlPdf.concat(downloadURL);
              setUrlPdf(arrUrlPdfTamp)
              setDownloadUrl(newPdfDownloadUrl);
              setPalgPDF(false);
              sendURlPDF(newPdfDownloadUrl);
              if (text == "") {
                setFile([]);
                setFormError(true);
                setMessErrHend(true);
              }
            }
            // Here you can add the download URL to your database or use it as needed
          });
        }
      );
    } else {
      console.error('No file selected');
    }

  };

  const update045Working = (sah158Input, cline042Input, working045Input) => {
    let worikng = working045;
    console.log("ffc", worikng)
    worikng += parseInt(working045Input);
    console.log("ffc - ", worikng)

    let arryData = [];
    setWorking045(worikng);
    addListHeand(sah158Show,
      cline042Show,
      working045Input)

    arryData.push({
      sah: sah158Show,
      cline: cline042Show,
      working: working045Input,
    })
    setInputValueWorking('');
    console.log("cf ", sah158Input, cline042Input, worikng)
    setDataPdfRemove([...arryDataPdfRemove, ...arryData]);
    setShow045Input(false)
  }

  const checkPDF = (fileN) => {
    sendYearURl();
    let sah158New = sah158;
    let cline042New = cline042;
    let working045New = working045;
    let sah158Show = 0;
    let cline042Show = 0;
    let working045Show = 0;
    let arryData = [];
    for (let i = 0; i < fileN.length; i++) {
      const file = fileN[i];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const typedArray = new Uint8Array(arrayBuffer);
          try {
            pdfjs.disableWorker = true;
            // Load the PDF document
            pdfjs.getDocument(typedArray).promise.then(async pdf => {
              // Loop through each page to extract text
              let fullText = '';
              let fullTextTow = '';
              for (let pageNum = 1; pageNum <= 1; pageNum++) {
                const pdfPage = await pdf.getPage(pageNum);
                const pageText = await pdfPage.getTextContent();
                const sortedTextItems = pageText.items.sort((a, b) => a.transform[5] - b.transform[5]);
                const pageStrings = sortedTextItems.map((item) => item.str);
                fullText = pageStrings.join(' ');
                handleUpload(fileN, fullText)
              }
              if (fullText.includes(`סה''כ שכר ותשלומים`)) {
                const numbers158T2 = fullText.split(`סה''כ שכר ותשלומים`)[1].match(/\d+/g);
                //158
                setSah158Show(numbers158T2[0] + numbers158T2[1])
                sah158Show = numbers158T2[0] + numbers158T2[1];
                sah158New += parseInt(sah158Show);
                setSah158(sah158New)
                console.log("c---++-", numbers158T2[0] + numbers158T2[1]);
                //042
                const numbers042T2 = fullText.split("ניכויים")[1].match(/\d+/g);
                numbers042T2.map((item, index) => {
                  if (item == "042") {
                    setCline042Show(numbers042T2[index - 2] + numbers042T2[index - 1]);
                    cline042Show = numbers042T2[index - 2] + numbers042T2[index - 1];
                    cline042New += parseInt(cline042Show);
                    setCline042(cline042New)
                    console.log("c042--", numbers042T2[index - 2] + numbers042T2[index - 1]);
                  } else {
                    setCline042Show(0);
                    setCline042(0)
                  }
                })
                //045
                const numbers045T2 = fullText.split("ניכויים")[1].match(/\d+/g);
                numbers045T2.map((item, index) => {
                  if (item == "045") {
                    setWorking045(numbers045T2[index - 2] + numbers045T2[index - 1]);
                    working045Show = numbers045T2[index - 2] + numbers045T2[index - 1]
                    working045New += parseInt(working045Show)
                    setWorking045(working045New)
                    console.log("c045--", numbers045T2[index - 2] + numbers045T2[index - 1]);
                  }
                })
                addListHeand(sah158Show,
                  cline042Show,
                  working045Show)

                arryData.push({
                  sah: sah158Show,
                  cline: cline042Show,
                  working: working045Show,
                })
                setDataPdfRemove([...arryDataPdfRemove, ...arryData]);
              }
              else {
                //158
                const numbers158 = fullText.split("חייבת קיצבה")[1].match(/\d+/g);
                if (numbers158[0] == 0) {
                  setSah158Show(parseInt(numbers158[3]));
                  sah158Show = parseInt(numbers158[3]);
                  sah158New += parseInt(numbers158[3]);
                } else {
                  setSah158Show(parseInt(numbers158[0]));
                  sah158Show = parseInt(numbers158[0]);
                  sah158New += parseInt(numbers158[0]);
                }

                //042
                const numbers042 = fullText.split("(012/011)")[1].match(/\d+/g);
                if (numbers042[0] == 0) {
                  setCline042Show(parseInt(numbers042[2]))
                  cline042Show = parseInt(numbers042[2]);
                  cline042New += parseInt(numbers042[2]);
                } else {
                  setCline042Show(parseInt(numbers042[0]))
                  cline042Show = parseInt(numbers042[0]);
                  cline042New += parseInt(numbers042[0]);
                }
                //045
                try {
                  const pdfPage = await pdf.getPage(2);
                  const pageText = await pdfPage.getTextContent();
                  const sortedTextItems = pageText.items.sort((a, b) => a.transform[5] - b.transform[5]);
                  const pageStrings = sortedTextItems.map((item) => item.str);
                  fullTextTow = pageStrings.join(' ');
                  const numbers045 = fullTextTow.split(`העובד הפקדות ש"ח`)[1].match(/\d+/g);
                  working045Show = parseInt(numbers045[0])
                  working045New += parseInt(numbers045[0])
                  setSah158(sah158New);
                  setCline042(cline042New);
                  setWorking045(working045New);
                  addListHeand(sah158Show,
                    cline042Show,
                    working045Show)

                  arryData.push({
                    sah: sah158Show,
                    cline: cline042Show,
                    working: working045Show,
                  })
                  setDataPdfRemove([...arryDataPdfRemove, ...arryData]);
                } catch {
                  //045-1
                  const numbers045 = fullText.split(`עמית  לקיצבה  לקופ"ג  העובד הפקדות ש"ח`)[1].match(/\d+/g);
                  setWorking045(parseInt(numbers045[0]))
                  working045Show = parseInt(numbers045[0]);
                  working045New += parseInt(numbers045[0]);
                  setWorking045(working045New);

                  addListHeand(sah158Show,
                    cline042Show,
                    working045Show)

                  arryData.push({
                    sah: sah158Show,
                    cline: cline042Show,
                    working: working045Show,
                  })
                  setSah158(sah158New);
                  setCline042(cline042New);
                  if (numbers045 == null) {
                    setShow045Input(true)
                  }
                }

              }
            });
          }
          catch (error) {
            setFile([]);
            setFormError(true);
            setMessErrHend(true);
            setPalgPDF(false)
            console.error('Error extracting text from PDF:', error);
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  }

  const handleAddHeand = () => {
    sendYearURl();

    setFormError(true);
    // let sah158New = 0;
    // let cline042New = 0;
    // let working045New = 0;
    // setFormError(true);
    // for (let i = 0; i < fileNew.length; i++) {
    //   const file = fileNew[i];
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.onload = async (e) => {
    //       const arrayBuffer = e.target.result;
    //       const typedArray = new Uint8Array(arrayBuffer);
    //       try {
    //         pdfjs.disableWorker = true;
    //         // Load the PDF document
    //         pdfjs.getDocument(typedArray).promise.then(async pdf => {
    //           // Loop through each page to extract text
    //           let fullText = '';
    //           let fullTextTow = '';
    //           for (let pageNum = 1; pageNum <= 1; pageNum++) {
    //             const pdfPage = await pdf.getPage(pageNum);
    //             const pageText = await pdfPage.getTextContent();
    //             const sortedTextItems = pageText.items.sort((a, b) => a.transform[5] - b.transform[5]);
    //             const pageStrings = sortedTextItems.map((item) => item.str);
    //             fullText = pageStrings.join(' ');
    //             if (pdf.getPage(2)) {
    //               const pdfPage = await pdf.getPage(2);
    //               const pageText = await pdfPage.getTextContent();
    //               const sortedTextItems = pageText.items.sort((a, b) => a.transform[5] - b.transform[5]);
    //               const pageStrings = sortedTextItems.map((item) => item.str);
    //               fullTextTow = pageStrings.join(' ');
    //             }
    //           }
    //           // sah158New += parseInt(fullText.split("(158/172)")[1].split('סה"כ')[1].split(" .")[0]);
    //           // cline042New += parseInt(fullText.split('סה"כ')[1].split('ש"ח')[0].split("מס")[1]);
    //           // working045New += parseInt(fullTextTow.split(`העובד הפקדות ש"ח`)[1].split(" .")[0])
    //           // setSah158(sah158New);
    //           // setCline042(cline042New);
    //           // setWorking045(working045New);
    //           // addListHeand(fullText.split("(158/172)")[1].split('סה"כ')[1].split(" .")[0],
    //           //   parseInt(fullText.split('סה"כ')[1].split('ש"ח')[0].split("מס")[1]),
    //           //   parseInt(fullTextTow.split(`העובד הפקדות ש"ח`)[1].split(" .")[0]))
    //         }).catch(error => {
    //           setFile([]);
    //           setFormError(true);
    //           console.error('Error extracting text from PDF:', error);
    //         })
    //       }
    //       catch (error) {
    //         setFile([]);
    //         setFormError(true);
    //         console.error('Error extracting text from PDF:', error);
    //       }
    //     };
    //     reader.readAsArrayBuffer(file);
    //   }
    // }

  }

  const handleFileChange = async () => {
    if (props.ifCheckPartner == false) {
      if (selectedOptionY !== "בחר שנה לבדיקה" && arrUrlPdf.length > 0) {
        if (isVisibleFormHend) {
          if (sah158 !== "" && cline042 !== "" && working045 !== "") {
            updateData(sah158, cline042, working045, inputValueName, selectedZN);
            props.upadteYearPdfUrl(selectedOptionY, pdfDownloadUrl)
          } else {
            setIsVisible(true);
            setTimeout(() => {
              setIsVisible(false);
            }, 3000);
          }
        } else {
          updateData(sah158, cline042, working045, inputValueName, selectedZN);
          props.upadteYearPdfUrl(selectedOptionY, pdfDownloadUrl)
        }
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 3000);
      }
    } else {
      if (selectedOptionY !== "בחר שנה לבדיקה" && arrUrlPdf.length > 0) {
        if (isVisibleFormHend) {
          if (sah158 !== "" && cline042 !== "" && working045 !== "") {
            if (selectedZN != "בחר זכר או נקבה" && inputValueName != "") {
              updateData(sah158, cline042, working045, inputValueName, selectedZN);
              props.upadteYearPdfUrl(selectedOptionY, pdfDownloadUrl)
            } else {
              setIsVisible(true);
              setTimeout(() => {
                setIsVisible(false);
              }, 3000);
            }
          } else {
            setIsVisible(true);
            setTimeout(() => {
              setIsVisible(false);
            }, 3000);
          }
        } else {
          if (selectedZN != "בחר זכר או נקבה" && inputValueName != "") {
            updateData(sah158, cline042, working045, inputValueName, selectedZN);
            props.upadteYearPdfUrl(selectedOptionY, pdfDownloadUrl)
          } else {
            setIsVisible(true);
            setTimeout(() => {
              setIsVisible(false);
            }, 3000);
          }
        }
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 3000);
      }

    }
  }



  const GetUrl = (fil, i) => {
    if (arrUrlPdf[i] != "") {
      window.open(arrUrlPdf[i], '_blank');
    }
    // Anchor tag to open the PDF document in a new tab
  }

  const rmoveUrl = (i) => {
    setSah158(sah158 => parseInt(sah158 - arryDataPdfRemove[i].sah))
    setCline042(cline042 => parseInt(cline042 - arryDataPdfRemove[i].cline))
    setWorking045(working045 => parseInt(working045 - arryDataPdfRemove[i].working))
    const tempArrayRemove = [...arryDataPdfRemove];
    tempArrayRemove.splice(i, 1);
    setDataPdfRemove(tempArrayRemove);
    const tempArray = [...fileNew];
    tempArray.splice(i, 1);
    setFile(tempArray);
    const tempArrayHend = [...sahAndCline];
    tempArrayHend.splice(i, 1);
    setAll(tempArrayHend)
    const tempArrayURL = [...pdfDownloadUrl];
    tempArrayURL.splice(i, 1);
    setDownloadUrl(tempArrayURL);
    const tampUrl = [...arrUrlPdf];
    tampUrl.splice(i, 1);
    setUrlPdf(tampUrl);
  }


  const renderFileList = () => (<div className=' row d-flex  row me-5 ms-5'>
    {[...fileNew].map((f, i) => (
      <div key={i}>
        <button className='button justify-content-center  mt-3 p-2' onClick={() => GetUrl(fileNew[i], i)} >טופס 106  - {i + 1} </button>
        <button className='buttonRemove me-3 p-2 fas fa-trash-alt' onClick={() => rmoveUrl(i)}></button>
      </div>
    ))}
  </div>)


  const addListHeand = (sah, cli, working) => {
    const sahcline = (
      "סה''כ " + sah + ' ,\n' +
      'ניכויי מס ' + cli + ` ,\n` +
      'הפקדות העובד ' + working
    ).toString();
    sahAndCline = sahAndCline.concat(sahcline);
    setAll(sahAndCline);
  }
  const addListTo158042 = (sah, cli, working) => {
    let arryData = [];
    console.log("eff", cli);
    if (sah != "" && cli != "" && working != "") {
      if (sah > 0 && cli >= 0 && working >= 0) {
        if (arrUrlPdf.length < 1) {
          const url = (
            ""
          ).toString();
          const tamp = arrUrlPdf.concat(url);
          setUrlPdf(tamp)
        } else {
          const url = (
            ""
          ).toString();
          const tamp = arrUrlPdf.concat(url);
          setUrlPdf(tamp)
        }
        console.log("mmm ", sah158, cline042, working045)
        sah158 += parseInt(sah);
        cline042 += parseInt(cli);
        working045 += parseInt(working);
        console.log("mmm - ", sah158, cline042, working045)
        //      console.log("mmm ", sah158, cline042, working045)
        setSah158(sah158);
        setCline042(cline042);
        setWorking045(working045);
        const sahcline = (
          "סה''כ " + sah + ' ,\n' +
          'ניכויי מס ' + cli + ` ,\n` +
          'הפקדות העובד ' + working
        ).toString();
        sahAndCline = sahAndCline.concat(sahcline);
        setAll(sahAndCline);
        arryData.push({
          sah: parseInt(sah),
          cline: parseInt(cli),
          working: parseInt(working)
        })
        setDataPdfRemove([...arryDataPdfRemove, ...arryData]);
        setInputValueSah('');
        setInputValueClien('');
        setInputValueWorking('');
        setMessErrHend(false);
      } else {
        if (working045 == 0) {
          setIsVisibleNumber(true);
          setTimeout(() => {
            setIsVisibleNumber(false);
          }, 3000);
        } else {
          setIsVisible(true);
          setTimeout(() => {
            setIsVisible(false);
          }, 3000);
        }
      }
    } else {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  }

  // const rmovesahAndCline = (i) => {
  //   setSah158(sah158 => parseInt(sah158 - arryDataPdfRemove[i].sah))
  //   setCline042(cline042 => parseInt(cline042 - arryDataPdfRemove[i].cline))
  //   setWorking045(working045 => parseInt(working045 - arryDataPdfRemove[i].working))
  //   const tempArrayRemove = [...arryDataPdfRemove];
  //   tempArrayRemove.splice(i, 1);
  //   setDataPdfRemove(tempArrayRemove);
  //   const tempArray = [...sahAndCline];
  //   tempArray.splice(i, 1);
  //   setAll(tempArray)
  // }

  const ListInput158 = () => (<div style={{ direction: 'rtl' }}>
    {[...sahAndCline].map((f, i) => (
      <div key={i} className=' d-flex justify-content-between  '>
        <button className='button justify-content-center  mt-3' onClick={() => GetUrl(arrUrlPdf[i], i)}>
          <h6 className=''>
            <div>
              {` - טופס  ${i + 1} -`}
              <br />
              {sahAndCline[i]}
            </div>
          </h6>
        </button>
        <button className='buttonRemove p-2 me-2 me-xl-4 mb-3 mt-4 fas fa-trash-alt' onClick={() => rmoveUrl(i)} ></button>
      </div>


    ))}
  </div>)

  const urlOpen = () => {
    window.open('https://drive.google.com/file/d/11m7L0WXqCBXl-0Cv8WmvepvUdOH82JGh/view?usp=sharing', '_blank');
  }
  const temp = () => {
    document.getElementById('someInput1').value = null;
  }
  return (
    <div>
      {
       pathname.includes("Shevah") &&  <h6 className=' text-white'>3 שנים אחורה</h6>
      }
      {
        !pathname.includes("Shevah") && <select className=' pe-3 ps-3 mt-2 select mb-2 myText p-1 ' value={selectedOptionY} onChange={handleSelectYear}>
          <option value="בחר שנה לבדיקה" className=''>בחר שנה לבדיקה</option>
          {y18 && <option value="2018">2018</option>}
          {y19 && <option value="2019">2019</option>}
          {y20 && <option value="2020">2020</option>}
          {y21 && <option value="2021">2021</option>}
          {y22 && <option value="2022">2022</option>}
          {y23 && <option value="2023">2023</option>}
        </select>
      }

      {
        props.ifCheckPartner && <div className=' d-flex justify-content-center'>
          <div className=''>

            <div>
              <select
                className='pe-3 ps-3 mt-2 myText select p-1'
                value={selectedZN}
                onChange={(e) => setSelectedZN(e.target.value)}>
                <option value="בחר זכר או נקבה">בחר זכר או נקבה</option>
                <option value="זכר">זכר</option>
                <option value="נקבה">נקבה</option>
              </select>
            </div>
            <input type="text" value={inputValueName} onChange={handleChange} className='form-control  mt-3' placeholder='שם בן/בת הזוג'></input>
          </div>
        </div>
      }
      {
        <div className='mt-3'>
          <input accept="application/pdf" type="file" id="someInput1" onClick={temp} onChange={(e) => addFile(e.target.files, e)} />
          <label htmlFor="someInput1" className="custom-file-input"> העלה קבצי 106 (PDF)</label>
          {
            !pathname.includes("Shevah") &&
            <div className=' mt-3'>
              <button style={{ border: "none" }} className="custom-file-input" onClick={handleAddHeand} >הכנס נתונים ידני</button>
            </div>
          }


          <a rel="noreferrer" className='row d-flex justify-content-center mt-3 text-danger text-decoration-none' target="_blank" href='https://login.gov.il/nidp/saml2/sso?id=usernamePasswordSMSOtp&sid=0&option=credential&sid=0'>* במידה ואין לך טופס 106, לחץ כאן לכניסה לאיזור האישי באתר הממשלתי</a>
          {/* {
            (pdfDownloadUrl.length > 0 && !palgPDf) && renderFileList()
          } */}


        </div>
      }
      {
        isVisibleFormHend && <div className='mt-2 text-white'>
          {
            isMessErrHend && <h5>מצטערים, המערכת לא זיהתה את טופס 106 שהעלת</h5>
          }
          <div className=' d-flex justify-content-center mt-2'>
            <div className=''>
              <div className=''>אנא הקש את הסכומים. מצורף טופס לדוגמה.
                <a rel="noreferrer" onClick={urlOpen} style={{ cursor: 'pointer' }} className=''>
                  <FaFilePdf className='me-2' color='red' size={25} />
                </a>
              </div>
            </div>
          </div>
          <div className=' d-xl-flex justify-content-center p-2'>
            <div className='col-xl-9'>
              <input type='number' value={inputValueSah} onChange={(e) => /^[0-9]*$/.test(e.target.value) && setInputValueSah(e.target.value)} ref={sah158Iput} className='form-control p-2 mt-2' placeholder="סה''כ (158/172)"></input>
              <input type='number' value={inputValueClien} onChange={(e) => /^[0-9]*$/.test(e.target.value) && setInputValueClien(e.target.value)} ref={cline042Iput} className='form-control p-2 mt-2' placeholder="סה''כ ניכויי מס (042)"></input>
              <input type='number' value={inputValueWorking} onChange={(e) => /^[0-9]*$/.test(e.target.value) && setInputValueWorking(e.target.value)} ref={working045Iput} className='form-control p-2 mt-2' placeholder="הפקדות העובד לקופ`ג (086/045)"></input>
            </div>
          </div>
          <button className="btn btn-secondary p-2 mt-3" onClick={() => addListTo158042(sah158Iput.current.value, cline042Iput.current.value, working045Iput.current.value)}>הוסף +</button>

        </div>

      }
      {
        show045Input && <div className="dialog-container state-back" style={{ direction: 'rtl' }}>
          <div className="dialog-content m-3">
            <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' >X</button>
            <h5 className='p-2'>{"נתון הבא לא זוהה"}</h5>
            <h6 className='p-1'>{"הפקדות העובד לקופ`ג (086/045)"}</h6>
            <div className='  mt-2'>
              <div>
                <input type='number' value={inputValueWorking} onChange={(e) => setInputValueWorking(e.target.value)} ref={working045Iput} className='form-control p-2 mt-2' placeholder={"הקלד נתון"}></input>
              </div>
              <button onClick={() => update045Working(sah158, cline042, inputValueWorking)} className="btn btn-dark col-6  mt-3" > אישור</button>
            </div>
          </div>
        </div>
      }
      {
        (palgPDf) &&
        <div className="charging-circle-container">
          <div className="charging-circle"></div>
        </div>
      }
      {(!palgPDf) && ListInput158()}
      <div className=' d-flex justify-content-between align-content-between'>
        {
          !props.ifCheckPartner && <button onClick={() => props.bakePage(1)} style={{ fontSize: '18px' }} className="btn btn-dark p-2  mt-3 border-3 border-success"> <RiArrowRightDoubleFill size={30} />
          </button>
        }
        <div></div>
        <button onClick={() => handleFileChange()} style={{ fontSize: '18px' }} className="btn btn-dark p-2 mt-3 border-3 border-success"> המשך </button>
      </div>
      {/* <div>{test11}</div> */}
      <div style={{ display: isVisible ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
        * השלם את כל הנתונים
      </div>
      <div style={{ display: isVisibleNumber ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
        * השדה הראשון חייב להיות גדול מאפס
      </div>
    </div>



    //  <div>
    //   <h2>Extracted Text:</h2>
    //   <p dir='rtl'>{sah158}</p>
    //   <p dir='rtl'>{cline042}</p>
    // </div>

  );


}


export default TakePDF;
