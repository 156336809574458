import React, { useEffect, useRef, useState } from 'react'
import ItemLid from './itemLid';
import { getDatabase, ref, onValue, limitToLast, get, remove, update, child, query, orderByKey, onChildChanged, onChildRemoved } from 'firebase/database';
import { IoIosPeople } from "react-icons/io";
import { useParams } from 'react-router-dom';
import '../home.css'
export default function ReturnToMas({zaki}) {
    const [dataListLid, setData] = useState([]);
    const [loading, setloading] = useState(true)
    let { name } = useParams();
    const timeoutRef = useRef(null);
    
    useEffect(() => {
        lisnDB();
        startTimeout();
        return () => {
            clearTimeoutIfNeeded();
        };
    }, []);

    function startTimeout() {
        const storedItems = JSON.parse(localStorage.getItem(`myArray${name}`)) || [];
        timeoutRef.current = setTimeout(() => {
            getList();
            if (storedItems.length === 0) {
                startTimeout(); // Restart the timeout if list is still empty
            }
        }, 1000);
    }

    function clearTimeoutIfNeeded() {
        clearTimeout(timeoutRef.current);
    }

    const lisnDB = async () => {
        const dbRef = ref(getDatabase());
        const itemsRef = child(dbRef, `/ron_mas/${name}/trueMas/`);
        const snapshott = await get(itemsRef);
        if (snapshott.size === 0) {
            setloading(false);
        }
        const handleChildAdded = async (snapshot) => {
            const storedItems = JSON.parse(localStorage.getItem(`myArray${name}`)) || [];
            const snapshott = await get(itemsRef);
            const count = snapshott.size;
            startTimeout();
            if (count > 0) {
                if (count == 1) {
                    doApiList();
                } else {
                    if (storedItems.length > 0) {
                        doApiList();
                    }
                }
            }
        };
        const queryRef = query(itemsRef, orderByKey(), limitToLast(1));
        onValue(queryRef, handleChildAdded);
        onChildChanged(queryRef, handleChildAdded)
        onChildRemoved(queryRef, handleChildAdded)
    };

    const doApiList = async () => {
        const db = getDatabase();
        const storedItems = JSON.parse(localStorage.getItem(`myArray${name}`)) || [];
        let myListData = [];
        try {
            const dbRef = ref(db, `/ron_mas/${name}/trueMas/`);

            const queryRef = query(dbRef, orderByKey(), limitToLast(1));
            const snapshot = await get(queryRef);


            if (snapshot.exists()) {
                const firebaseData = snapshot.val();
                if (firebaseData) {
                    for (const [idKay, item] of Object.entries(firebaseData)) {
                        const detailsTest1 = [];
                        const yearRef = ref(db, `/ron_mas/${name}/trueMas/${idKay}/year/`);
                        const yearSnapshot = await get(yearRef);

                        if (yearSnapshot.exists()) {
                            const yearData = yearSnapshot.val();

                            for (const [idKay2, itemYear] of Object.entries(yearData)) {
                                const pdfRef = ref(db, `/ron_mas/${name}/trueMas/${idKay}/year/${idKay2}/pdf`);
                                const pdfRefM = ref(db, `/ron_mas/${name}/trueMas/${idKay}/year/${idKay2}/pdfM`);
                                const pdfSnapshot = await get(pdfRef);
                                const pdfSnapshotM = await get(pdfRefM);
                                if (pdfSnapshot.exists()) {
                                    const pdfData = pdfSnapshot.val();
                                    const pdfArray = Object.values(pdfData);
                                    if (pdfSnapshotM.exists()) {
                                        const pdfDataM = pdfSnapshotM.val();
                                        const pdfArrayM = Object.values(pdfDataM);
                                        detailsTest1.push({
                                            year: itemYear.year,
                                            namem: itemYear.nameM,
                                            sum: itemYear.sum,
                                            sumM: itemYear.sumM,
                                            sahclin: itemYear.sahclin,
                                            sahclinM: itemYear.sahclinM,
                                            ans: itemYear.ans,
                                            tromaUrl: itemYear.TromaUrl == null ? "" :itemYear.TromaUrl ,
                                            lumiUrl: itemYear.lumUrl == null ? "" :itemYear.lumUrl,
                                            lumiUrlM: itemYear.lumUrlM == null ? "" :itemYear.lumUrlM,
                                            dataClac: itemYear.dataClac,
                                            dataClacM: itemYear.dataClacM,
                                            isChecked: false,
                                            enterMesM: itemYear.enterMesM,
                                            koptGemlM: itemYear.koptGemlM,
                                            safeLifeM: itemYear.safeLifeM,
                                            pdf: pdfArray,
                                            pdfM: pdfArrayM
                                        });
                                    }
                                    else {
                                        detailsTest1.push({
                                            year: itemYear.year,
                                            sum: itemYear.sum,
                                            sahclin: itemYear.sahclin,
                                            ans: itemYear.ans,
                                            tromaUrl: itemYear.TromaUrl == null ? "" :itemYear.TromaUrl ,
                                            lumiUrl: itemYear.lumUrl == null ? "" :itemYear.lumUrl,
                                            lumiUrlM: itemYear.lumUrlM == null ? "" :itemYear.lumUrlM,
                                            dataClac: itemYear.dataClac,
                                            dataClacM: itemYear.dataClacM,
                                            isChecked: false,
                                            enterMesM: itemYear.enterMesM,
                                            koptGemlM: itemYear.koptGemlM,
                                            pdf: pdfArray
                                        });
                                    }
                                }
                            }

                            myListData.push({
                                name: item.name,
                                id: item.id,
                                idkay: idKay,
                                date: item.date,
                                phone: item.phone,
                                color: item.color,
                                resPerson: item.resPerson,
                                isChecked: false,
                                details: detailsTest1
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        if (myListData.length > 0) {
            const updatedItems = [...myListData, ...storedItems.filter(item => !myListData.some(newItem => newItem.idkay === item.idkay))];
            setData(updatedItems);
            localStorage.setItem(`myArray${name}`, JSON.stringify(updatedItems));
        } else {
            console.log("No data fetched from Firebase.");
        }
    };

    const getList = () => {
        const localStorageArray = JSON.parse(localStorage.getItem(`myArray${name}`)) || [];

        if (localStorageArray.length > 0) {
            setData(localStorageArray);
            setloading(false)
        }
    }

    const getData = () => {
        const storedArray = JSON.parse(localStorage.getItem(`myArray${name}`)) || [];
        setData(storedArray);
        setloading(false)
    }


    const removeItem = (idRemove) => {
        let storedArray = [];
        const db = getDatabase();
        const itemRef = ref(db, `/ron_mas/${name}/trueMas/` + idRemove);
        remove(itemRef);
        storedArray = dataListLid.filter((item, index) => item.idkay !== idRemove)
        setData(storedArray);
        localStorage.setItem(`myArray${name}`, JSON.stringify(storedArray));
    };

    const updateColor = (idKey, color) => {
        let storedArray = [];
        storedArray = dataListLid.map((item, index) => {
            if (item.idkay == idKey) {
                dataListLid[index].color = color;
                localStorage.setItem(`myArray${name}`, JSON.stringify(dataListLid));
            }
        })
        const db = getDatabase();
        const itemRefUp = ref(db, `/ron_mas/${name}/trueMas/` + idKey);
        update(itemRefUp, {
            'color': color
        }, (error) => {
            if (error) {
                console.error("Error updating data:", error);
            } else {
                //setUpdateSuccess(true);
            }
        });
    }



    return (
        <div className='' >
            {loading &&
                <div className="charging-circle-container">
                    <div className="charging-circle"></div>
                </div>
            }
            {!loading && dataListLid.map((person, index) => (
                person.resPerson == zaki &&
                <div key={index} className='an'>
                    <ItemLid key={index} color={person.color} updateColor={updateColor} removeItem={removeItem} person={person} />
                </div>
            ))
            }
            {
                ((dataListLid.length == 0 && loading == false) && <div className='mt-3 p-4'>
                    <div className=' d-flex justify-content-center mb-2'>
                        <IoIosPeople className=' text-white' size={45} />
                    </div>
                    <div className=' d-flex justify-content-center'>
                        <h2 className=' text-white'>אין כרגע לקוחות</h2>
                    </div>
                </div>)
            }
        </div>
    )
}
