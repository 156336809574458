import { FaPhoneAlt } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa";
import React, { useEffect, useRef, useState } from 'react'
import '../lid.css'
import { getDatabase, ref, update, get, child, set } from "firebase/database";
import { FaRegStopCircle } from "react-icons/fa";
import { FaPlayCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, updatePassword } from "firebase/auth";


export default function ItemManger(props) {
    const [isOpen, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState("d/m/y");
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoad] = useState(false)
    const [mess, setMess] = useState(false);
    const [messErr, setmessErr] = useState(false);
    const [isOpenRemove, setIsOpenRemove] = useState(false)
    const [stopSite, setstopSite] = useState(false)
    const [showChangePass, setshowChangePass] = useState(false)
    const newPassword = useRef();
    const { name } = useParams();


    useEffect(() => {
        setSelectedOption(props.item.timeService);
        setSelectedDate(props.item.dateStartService);
        setstopSite(props.item.userWork == "off" ? true : false);
    }, [])

    const handleSelectService = (event) => {
        setSelectedOption(event.target.value)
    };

    const toggleBox = () => {
        setOpen(!isOpen)
    };

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        setSelectedDate(selectedDate);
    };

    const handleClick = (phone) => {
        window.location = `tel:${phone}`;
    };

    const upDataFireBase = () => {
        setLoad(true);
        const db = getDatabase();
        props.updateDataManger(props.item.name, selectedOption, selectedDate, stopSite)
        update(ref(db, 'ron_mas/' + `${props.item.name}` + "/details/"), {
            'timeService': selectedOption == null ? 0 : selectedOption,
            'dateStartService': selectedDate == null ? 0 : selectedDate,
        }).then(() => {
            setLoad(false);
            setMess(true);
            setTimeout(() => {
                setMess(false);
            }, 3000);
        });
    }

    const toggleBoxFinish = () => {
        setIsOpenRemove(false);
    };
    const toggleBoxYes = (name, email, password) => {
        setIsOpenRemove(false);
        setOpen(false)
        props.removeItem(name, email ,password)
    };

    const changeStatusWeb = (status) => {
        setstopSite(status);
        const db = ref(getDatabase());
        const dbb = getDatabase();
        get(child(db, `/ron_mas/${props.item.name}/`)).then((snapshot) => {
            const itemRefUp = ref(dbb, `ron_mas/${props.item.name}`);
            update(itemRefUp, {
                'userWork': status == true ? "off" : "on"
            }, (error) => {
                if (error) {
                    console.error("Error updating data:", error);
                } else {
                    //setUpdateSuccess(true);
                }
            });
        }).catch((error) => {
            console.error(error);
        });

    }


    const handleChangePassword = async (email, pass, nameUser , newPas) => {
        const auth = getAuth();
        setLoad(true)
        const db = getDatabase();
        try {
            // Sign in the user with email and password
            const userCredential = await signInWithEmailAndPassword(auth, email, pass);
            const user = userCredential.user;
        
            // Update the password for the signed-in user
            await updatePassword(user, newPas);
            update(ref(db, 'ron_mas/' + nameUser + "/details/"), {
                "password": newPas,
            }).then(()=> {
                setLoad(false);
                setMess(true);
                setTimeout(() => {
                    setMess(false);
                    setshowChangePass(false)
                }, 3000);
            })
            // Optionally, you can handle success here, e.g., show a success message
            console.log('Password updated successfully');
          } catch (error) {
            // Handle specific errors or generic error messages
            if (error.code === 'auth/weak-password') {
              setmessErr("מנימום 6 תוים בסיסמה");
            } else {
              setmessErr("קיימת שגיאה בעדכון הסיסמה");
            }
        
            // Clear error message after 3 seconds
            setTimeout(() => {
              setmessErr("");
            }, 3000);
        
            // Log the error for debugging purposes
            console.error('Error updating password:', error.message);
          } finally {
            // Set loading state to false after handling the error or successful update
            setLoad(false);
          }


    };


    return (
        <div className=" broderLog p-1 mt-2 bg-white col-12 col-xl-12 ">
            <div onClick={toggleBox} className=' d-flex justify-content-between '>
                <h5 className='mt-1 me-2'> {props.item.name}  </h5>
                <div className=' d-flex align-items-baseline'>

                    <div className=' d-flex'>
                        <FaChevronDown className='mt-1 ms-2' size={22} onClick={toggleBox} />
                    </div>
                </div>
            </div>
            {
                isOpen && <div>
                    <hr className='hrBox'></hr>
                    <div className=" d-flex justify-content-end mb-2 ms-2">
                        <h6 onClick={() => handleClick(props.item.phone)}> {props.item.phone}</h6>
                        <button style={{ background: "none", border: "none", textDecoration: "none" }} className=' text-dark'>
                            <FaPhoneAlt onClick={() => handleClick(props.item.phone)} size={25} color="green" className=' mb-2' />
                        </button>
                    </div>

                    <h6 className=" text-center"> משך זמן שירות</h6>
                    <div className="d-flex justify-content-center ps-2 pe-2 ">
                        <select className='form-control select' value={selectedOption} onChange={handleSelectService}>
                            <option value="" className=''>בחר</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </div>
                    <div className=" d-flex justify-content-around mt-4">
                        <h6 className="me-1">התחלת השירות</h6>
                        {/* <h6 className="ms-1">סיום השירות</h6> */}
                    </div>
                    <div className=" d-flex justify-content-center ps-2 pe-2 mb-4">
                        <input className="select form-control" value={selectedDate} onChange={handleDateChange} type="date" ></input>
                        {/* <input className="select form-control me-2" type="date"></input> */}
                    </div>
                    <div className="  text-center p-1 ">
                        <a className="text-primary" style={{ textDecoration: "none" }} target="_blank" href={props.item.link}>
                            <h6> לאתר של {props.item.name}</h6>
                        </a>
                        <h6>{props.item.email}</h6>
                        <h6>{props.item.password}</h6>
                    </div>
                    <div className=" d-flex justify-content-between mb-1 me-2">
                        <button onClick={upDataFireBase} className="button">
                            עדכן נתונים
                        </button>

                        <div>
                            <button style={{ border: 'none', outline: 'none', background: 'none' }}>
                                {
                                    stopSite == false && <FaRegStopCircle onClick={() => changeStatusWeb(true)} className='mt-1 ms-3' color='red' size={25} />
                                }
                                {
                                    stopSite == true && <FaPlayCircle onClick={() => changeStatusWeb(false)} className='mt-1 ms-3' color='green' size={25} />
                                }

                            </button>
                            <button style={{ border: 'none', outline: 'none', background: 'none' }}>
                                <FaRegTrashCan onClick={() => setIsOpenRemove(true)} className='mt-1 ms-1' color='red' size={23} />
                            </button>
                        </div>
                    </div>
                    <hr/>
                    <button onClick={() => setshowChangePass(showChangePass => !showChangePass)} className="button me-2 mb-2">
                        שינוי סיסמה
                    </button>
                
                    {
                        showChangePass && <div >
                            <div className=" p-3">
                                <input ref={newPassword} className=" form-control" placeholder="סיסמה חדשה"></input>
                            </div>
                            <button onClick={() => handleChangePassword(props.item.email, props.item.password  , props.item.name, newPassword.current.value)} className="button me-2 m2">
                                עדכן סיסמה
                            </button>
                        </div>
                    }
                    {
                        loading && <div className="charging-circle-container">
                            <div className="charging-circle mb-5"></div>
                        </div>
                    }
                    {mess &&
                        <div className=' d-flex justify-content-center'>
                            <h4 style={{ direction: 'ltr' }} className=' text-dark mt-3'>! עודכן בהצלחה</h4>
                        </div>
                    }
                    {messErr &&
                        <div className=' d-flex justify-content-center'>
                            <h4 style={{ direction: 'ltr' }} className=' text-danger mt-3'>{messErr}</h4>
                        </div>
                    }
                    {
                        isOpenRemove && (
                            <div className="dialog-container" style={{ direction: 'rtl' }}>

                                <div className="dialog-content">
                                    <div className=' d-flex justify-content-start'>
                                        <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} onClick={toggleBoxFinish} className='text-danger'>X</button>
                                    </div>
                                    <div className='p-3'>
                                        <h5>בטוח שברצונך למחוק ?</h5>
                                        <div className=' d-flex justify-content-between mt-4 ms-2'>
                                            <button onClick={() => toggleBoxYes(props.item.name, props.item.email , props.item.password)} className='mt-1 me-2 custom-button'>כן</button>
                                            <button onClick={toggleBoxFinish} className='mt-1 me-2 custom-button'>בטל</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}
