import React, { useEffect, useState } from 'react'
import '../step.css'
import { useNavigate, useParams } from 'react-router-dom';
import { LuPartyPopper } from "react-icons/lu";
import JSConfetti from 'js-confetti'


const years = [
    //2018 - 0
    [
        { m1: 74880, ahoz1: 0.10, plus1: 74881 },
        { m2: 107400, ahoz2: 0.14, plus2: 107401, minus21: 32520 },
        { m3: 172320, ahoz3: 0.20, plus3: 172321, minus32: 64920 },
        { m4: 239520, ahoz4: 0.31, plus4: 239521, minus43: 67200 },
        { m5: 498360, ahoz5: 0.35, plus5: 498361, minus54: 258840 },
        { m6: 641880, ahoz6: 0.47, plus6: 641881, minus65: 143520 },
    ],
    //2019 - 1
    [
        { m1: 75720, ahoz1: 0.10, plus1: 75721 },
        { m2: 108600, ahoz2: 0.14, plus2: 108601, minus21: 32880 },
        { m3: 174360, ahoz3: 0.20, plus3: 174361, minus32: 65760 },
        { m4: 242400, ahoz4: 0.31, plus4: 242401, minus43: 68040 },
        { m5: 504360, ahoz5: 0.35, plus5: 504361, minus54: 261960 },
        { m6: 649560, ahoz6: 0.47, plus6: 649561, minus65: 145200 },
    ],
    //2020 - 2
    [
        { m1: 75960, ahoz1: 0.10, plus1: 75961 },
        { m2: 108960, ahoz2: 0.14, plus2: 108961, minus21: 33000 },
        { m3: 174960, ahoz3: 0.20, plus3: 174961, minus32: 66000 },
        { m4: 243120, ahoz4: 0.31, plus4: 243121, minus43: 68160 },
        { m5: 505920, ahoz5: 0.35, plus5: 505921, minus54: 262800 },
        { m6: 651600, ahoz6: 0.47, plus6: 651601, minus65: 145680 },
    ],
    //2021 - 3
    [
        { m1: 75480, ahoz1: 0.10, plus1: 75481 },
        { m2: 108360, ahoz2: 0.14, plus2: 108361, minus21: 32880 },
        { m3: 173880, ahoz3: 0.20, plus3: 173881, minus32: 65520 },
        { m4: 241680, ahoz4: 0.31, plus4: 241681, minus43: 67800 },
        { m5: 502920, ahoz5: 0.35, plus5: 502921, minus54: 261240 },
        { m6: 647640, ahoz6: 0.47, plus6: 647641, minus65: 144720 },
    ],
    //2022 - 4
    [
        { m1: 77400, ahoz1: 0.10, plus1: 77401 },
        { m2: 110880, ahoz2: 0.14, plus2: 110881, minus21: 33480 },
        { m3: 178080, ahoz3: 0.20, plus3: 178081, minus32: 67200 },
        { m4: 247440, ahoz4: 0.31, plus4: 247441, minus43: 69360 },
        { m5: 514920, ahoz5: 0.35, plus5: 514921, minus54: 267480 },
        { m6: 663240, ahoz6: 0.47, plus6: 663241, minus65: 148320 },
    ],
    //2023 - 5
    [
        { m1: 81480, ahoz1: 0.10, plus1: 81481 },
        { m2: 116760, ahoz2: 0.14, plus2: 116761, minus21: 35280 },
        { m3: 187440, ahoz3: 0.20, plus3: 187441, minus32: 70680 },
        { m4: 260520, ahoz4: 0.31, plus4: 260521, minus43: 73080 },
        { m5: 542160, ahoz5: 0.35, plus5: 542161, minus54: 281640 },
        { m6: 698280, ahoz6: 0.47, plus6: 698281, minus65: 156120 },
    ],
    //2024 - 6
    [
        { m1: 84120, ahoz1: 0.10, plus1: 84121 },
        { m2: 120720, ahoz2: 0.14, plus2: 120721, minus21: 36600 },
        { m3: 193800, ahoz3: 0.20, plus3: 193801, minus32: 73080 },
        { m4: 269280, ahoz4: 0.31, plus4: 269281, minus43: 75480 },
        { m5: 560280, ahoz5: 0.35, plus5: 560281, minus54: 291000 },
        { m6: 721560, ahoz6: 0.47, plus6: 721561, minus65: 161280 },
    ]
]

const pointBackSum = [
    //2018
    2592,
    //2019 
    2616,
    //2020
    2628,
    //2021 
    2616,
    //2022
    2676,
    //2023
    2820
]




function ClacMasPage(props) {
    const [resultSum, setResultSum] = useState(0);
    const [resultPerson, setResultPreson] = useState('');
    const [sumPointZ, setPointZ] = useState(0);
    const [sumTroma, setTroma] = useState(0);
    const [res, setT] = useState(false);
    const [isOpenMess, setIsOpenMess] = useState(true)
    const [clickFinish, setclickFinish] = useState(false)
    const jsConfetti = new JSConfetti()

    let { name } = useParams();
    const nav = useNavigate();

    const backHomeFinish = () => {
        nav(`/${name}`)
    }
    const toggleBoxFinish = () => {
        setIsOpenMess(false);
    };

    useEffect(() => {
        console.log("sahh ", props.sum158, props.cline042)
        if (props.selctedZN == "בחר זכר או נקבה") {
            clasZikoi(props.ans, props.year);
        } else {
            clacSikoiN(props.ans, props.year)
        }
    }, [])


    const triggerConfetti = () => {
        jsConfetti.addConfetti({
            confettiColors: ['#0df6cf', '#001c42', '#001c42', '#fff'],
            confettiRadius: 7,
            confettiNumber: 1001,
        }).then(() => {
            jsConfetti.addConfetti({
                confettiColors: ['#0df6cf', '#001c42', '#001c42', '#fff'],
                confettiRadius: 7,
                confettiNumber: 900,
            });
        });

    };

    const setResult = (sum, sumPoingZ, cline, sumTroma, sumPoint) => {
        let sumWorking045 = 2851.8;
        let safeLife025 = parseInt(props.safeLife * 0.25)
        let safeLifeM025 = parseInt(props.safeLifeM * 0.25)
        const working45 = parseInt(props.working045 * 0.35)
        if (parseInt(working45) < 2851.8) {
            sumWorking045 = parseInt(props.working045 * 0.35).toFixed(1);
        }
        if (sum == 0) {
            let isNegtiv = parseInt(0 - cline)
            setResultSum(isNegtiv.toFixed(0));
            if (props.ifCheckPartner) {
                if (parseInt(isNegtiv + parseInt(props.oneMrry)) >= 0) {
                    props.resultSum(parseInt(isNegtiv), sum, 0, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                    setResultSum(parseInt(isNegtiv + parseInt(props.oneMrry)));
                    setResultPreson('תודה שהשתמשת בשירותינו :) אחד מנציגנו יצור איתך קשר בהקדם ')
                }
                else {
                    props.resultSum(parseInt(isNegtiv), sum, 0, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                    setResultSum(parseInt(isNegtiv + parseInt(props.oneMrry)));
                    setResultPreson("אתה זכאי להחזר מס! בקרוב אחד מנציגנו יצור איתך קשר ונלווה אותך בתהליך")
                    setT(true);
                    triggerConfetti();
                }
            }
            else {
                if (isNegtiv >= 0) {
                    props.resultSum(isNegtiv.toFixed(0), sum, 0, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                    setResultPreson('תודה שהשתמשת בשירותינו :) אחד מנציגנו יצור איתך קשר בהקדם ')
                }
                else {
                    props.resultSum(isNegtiv.toFixed(0), sum, 0, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                    setResultPreson("אתה זכאי להחזר מס! בקרוב אחד מנציגנו יצור איתך קשר ונלווה אותך בתהליך")
                    setT(true);
                    triggerConfetti();
                }
            }
        }
        else {

            let sumData = parseInt(sum - sumPoingZ - sumTroma - sumWorking045);
            if (sumData < 0) {
                if (!props.ifCheckPartner) {
                    sumData = parseInt(sumData - safeLife025)
                } else {
                    sumData = parseInt(sumData - safeLifeM025)
                }
                console.log("CalculatorResult", sum, "afterResult", sumData, "ponitSum", sumPoingZ, "045", sumWorking045, "troma", sumTroma, "result")
                let isNegtiv = parseInt(0 - cline)
                setResultSum(isNegtiv.toFixed(0));
                if (props.ifCheckPartner) {
                    if (parseInt(isNegtiv + parseInt(props.oneMrry)) >= 0) {
                        props.resultSum(parseInt(isNegtiv), sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultSum(parseInt(isNegtiv + parseInt(props.oneMrry)));
                        setResultPreson('תודה שהשתמשת בשירותינו :) אחד מנציגנו יצור איתך קשר בהקדם ')
                    }
                    else {
                        props.resultSum(parseInt(isNegtiv), sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultSum(parseInt(isNegtiv + parseInt(props.oneMrry)), sum, sumData, sumPoingZ, sumTroma, sumWorking045);
                        setResultPreson("אתה זכאי להחזר מס! בקרוב אחד מנציגנו יצור איתך קשר ונלווה אותך בתהליך")
                        setT(true);
                        triggerConfetti();
                    }
                } else {
                    if (isNegtiv >= 0) {
                        props.resultSum(isNegtiv.toFixed(0), sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultPreson('תודה שהשתמשת בשירותינו :) אחד מנציגנו יצור איתך קשר בהקדם ')
                    }
                    else {
                        props.resultSum(isNegtiv.toFixed(0), sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultPreson("אתה זכאי להחזר מס! בקרוב אחד מנציגנו יצור איתך קשר ונלווה אותך בתהליך")
                        setT(true);
                        triggerConfetti();
                    }
                }
            }
            else {
                let isNegtiv = 0;
                if (!props.ifCheckPartner) {
                    sumData = parseInt(sumData - parseInt(props.safeLife * 0.25))
                } else {
                    sumData = parseInt(sumData - parseInt(props.safeLifeM * 0.25))
                }
                if (sumData <= cline) {
                    //אם קטן ושלילי אז  יש החזר אם גדול ושווה תשלום מס באדום 
                    isNegtiv = parseInt(sumData - cline)
                } else {
                    //sum 150 cin 100 -> 150 - 100 = 50
                    //אם גדול או שווה ל 042 אין החזר 
                    isNegtiv = parseInt(sumData - cline)
                }
                console.log("CalculatorResult", sum, "afterResult", sumData, "ponitSum", sumPoingZ, "045", sumWorking045, "troma", sumTroma, "safeLifeOne", props.safeLife, "safeLifeTow", props.safeLifeM, "result", isNegtiv)

                setResultSum(isNegtiv.toFixed(0));
                if (props.ifCheckPartner) {
                    if (parseInt(isNegtiv + parseInt(props.oneMrry)) > 0) {
                        props.resultSum(isNegtiv, sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultSum(parseInt(isNegtiv + parseInt(props.oneMrry)));
                        setResultPreson('תודה שהשתמשת בשירותינו :) אחד מנציגנו יצור איתך קשר בהקדם ')
                    }
                    else {
                        console.log("a--a", isNegtiv)
                        props.resultSum(isNegtiv, sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultSum(parseInt(isNegtiv + parseInt(props.oneMrry)));
                        setResultPreson("אתה זכאי להחזר מס! בקרוב אחד מנציגנו יצור איתך קשר ונלווה אותך בתהליך")
                        setT(true);
                        triggerConfetti();
                    }
                } else {
                    if (isNegtiv > 0) {
                        props.resultSum(isNegtiv.toFixed(0), sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultPreson('תודה שהשתמשת בשירותינו :) אחד מנציגנו יצור איתך קשר בהקדם ')
                    }
                    else {
                        props.resultSum(isNegtiv.toFixed(0), sum, sumData, sumPoingZ, sumTroma, sumWorking045, sumPoint)
                        setResultPreson("אתה זכאי להחזר מס! בקרוב אחד מנציגנו יצור איתך קשר ונלווה אותך בתהליך")
                        setT(true);
                        triggerConfetti();
                    }
                }
            }
        }
    }

    const clacSikoiN = (ans, year) => {
        let sum = 2.25;
        if (props.selctedZN == "נקבה") {
            sum += 0.5;
        }
        //?יש ילדים
        if (ans[2] == 'כן') {
            //מתחת לגיל שנה
            if (ans[4] > 0) {
                sum += ans[4] * 1.5;
            }
            //מתחת לגיל 6
            if (ans[5] > 0) {
                sum += ans[5] * 2.5;
            }
            //מתחת לגיל 13
            if (ans[6] > 0) {
                if (year <= 2021 && props.selctedZN == "נקבה") {
                    sum += ans[6] * 1;
                }
                if (year >= 2022 && props.selctedZN == "נקבה") {
                    sum += ans[6] * 2;
                }
                if (year >= 2022 && props.selctedZN == "זכר") {
                    sum += ans[6] * 1;
                }
            }
            //מתחת לגיל 18
            if (ans[7] > 0) {
                if (year <= 2021 && props.selctedZN == "נקבה") {
                    sum += ans[7] * 1;
                }
                if (year >= 2022 && props.selctedZN == "נקבה") {
                    sum += ans[7] * 1;
                }
            }
        }
        console.log("pointZ ", sum)
        switch (year) {
            case '2018':
                setPointZ(parseInt(2592 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2592 * sum) ,0, sum)
                break;
            case '2019':
                setPointZ(parseInt(2616 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2616 * sum),0, sum)
                break;
            case '2020':
                setPointZ(parseInt(2628 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2628 * sum),0, sum)
                break;
            case '2021':
                setPointZ(parseInt(2616 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2616 * sum),0, sum)
                break;
            case '2022':
                setPointZ(parseInt(2676 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2676 * sum), 0, sum)
                break;
            case '2023':
                setPointZ(parseInt(2820 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2820 * sum), 0, sum)
                break;
            default:
                break;
        }
    }
    const clasZikoi = (ans, year) => {
        let sum = 2.25;
        let sumTroma = 0;
        console.log("ans", ans);
        //נקבה
        if (ans[0] == "נקבה") {
            sum += 0.5;
        }
        //?יש ילדים
        if (ans[2] == 'כן') {
            //מתחת לגיל שנה
            if (ans[4] > 0) {
                sum += ans[4] * 1.5;
            }
            //מתחת לגיל 6
            if (ans[5] > 0) {
                sum += ans[5] * 2.5;
            }
            //מתחת לגיל 13
            if (ans[6] > 0) {
                if (year <= 2021 && ans[0] == "נקבה") {
                    sum += ans[6] * 1;
                }
                if (year >= 2022 && ans[0] == "נקבה") {
                    sum += ans[6] * 2;
                }
                if (year >= 2022 && ans[0] == "זכר") {
                    sum += ans[6] * 1;
                }
            }
            //מתחת לגיל 18
            if (ans[7] > 0) {
                if (year <= 2021 && ans[0] == "נקבה") {
                    sum += ans[7] * 1;
                }
                if (year >= 2022 && ans[0] == "נקבה") {
                    sum += ans[7] * 1;
                }
            }
        }
        //נטול יכולת
        if (ans[8] == "כן") {
            sum += 2;
        }
        //תרומות
        if (ans[10] == "כן") {
            if (year == 2018) {
                //מנימום תרומה
                if (ans[11] >= 180) {
                    if (props.sum158 * 0.30 < ans[11]) {
                        sumTroma = props.sum158 * 0.30 * 0.35;
                        setTroma(props.sum158 * 0.30 * 0.35)
                    } else {
                        sumTroma = ans[11] * 0.35;
                        setTroma(ans[11] * 0.35)
                    }
                }
            }
            if (year >= 2019 && year <= 2022) {
                //מנימום תרומה
                if (ans[11] >= 190) {
                    if (props.sum158 * 0.30 < ans[11]) {
                        sumTroma = props.sum158 * 0.30 * 0.35;
                        setTroma(props.sum158 * 0.30 * 0.35)
                    } else {
                        sumTroma = ans[11] * 0.35;
                        setTroma(ans[11] * 0.35)
                    }
                }
            }
            if (year == 2023) {
                //מנימום תרומה
                if (ans[11] >= 200) {
                    if (props.sum158 * 0.30 < ans[11]) {
                        sumTroma = props.sum158 * 0.30 * 0.35;
                        setTroma(props.sum158 * 0.30 * 0.35)
                    } else {
                        sumTroma = ans[11] * 0.35;
                        setTroma(ans[11] * 0.35)
                    }
                }
            }
        }

        console.log("point mas", sum);
        switch (year) {
            case '2018':
                setPointZ(parseInt(2592 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2592 * sum), sumTroma, sum)
                break;
            case '2019':
                setPointZ(parseInt(2616 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2616 * sum), sumTroma, sum)
                break;
            case '2020':
                setPointZ(parseInt(2628 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2628 * sum), sumTroma, sum)
                break;
            case '2021':
                setPointZ(parseInt(2616 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2616 * sum), sumTroma, sum)
                break;
            case '2022':
                setPointZ(parseInt(2676 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2676 * sum), sumTroma, sum)
                break;
            case '2023':
                setPointZ(parseInt(2820 * sum));
                ClacMas(props.sum158, props.cline042, props.working045, props.year, parseInt(2820 * sum), sumTroma, sum)
                break;
            default:
                break;
        }
    }

    const ClacMas = (sum158, cline042, working045, year, sumPoingZ, sumTroma, sumPoint) => {
        let broto = sum158;
        let cline = cline042;
        let indexYear = 999;
        switch (year) {
            case '2018':
                indexYear = 0;
                break;
            case '2019':
                indexYear = 1;
                break;
            case '2020':
                indexYear = 2;
                break;
            case '2021':
                indexYear = 3;
                break;
            case '2022':
                indexYear = 4;
                break;
            case '2023':
                indexYear = 5;
                break;
            default:
                break;
        }
        //10%
        if (broto <= years[indexYear][0].m1) {
            let result = broto * years[indexYear][0].ahoz1;
            setResult(result.toFixed(0), sumPoingZ.toFixed(0), cline, sumTroma.toFixed(0), sumPoint);
            return;
        }
        //14%
        if (broto <= years[indexYear][1].m2) {
            let result = ((years[indexYear][0].m1 * years[indexYear][0].ahoz1) + (broto - (years[indexYear][0].plus1)) * years[indexYear][1].ahoz2);
            setResult(result.toFixed(0), sumPoingZ, cline, sumTroma, sumPoint);
            return;
        }
        //20%
        if (broto <= years[indexYear][2].m3) {
            let result = ((((years[indexYear][0].m1 * years[indexYear][0].ahoz1)
                + (years[indexYear][1].m2 - (years[indexYear][0].plus1)) * years[indexYear][1].ahoz2) +
                (broto - years[indexYear][1].plus2) * years[indexYear][2].ahoz3));
            setResult(result.toFixed(0), sumPoingZ, cline, sumTroma, sumPoint);
            return;
        }
        //31%
        if (broto <= years[indexYear][3].m4) {
            let result = (((((years[indexYear][0].m1 * years[indexYear][0].ahoz1)

                + (years[indexYear][1].m2 - (years[indexYear][0].plus1)) * years[indexYear][1].ahoz2

                + (years[indexYear][2].m3 - (years[indexYear][1].plus2)) * years[indexYear][2].ahoz3))

                + (broto - years[indexYear][2].plus3) * years[indexYear][3].ahoz4));
            setResult(result.toFixed(0), sumPoingZ, cline, sumTroma, sumPoint);
            return;
        }
        //35%
        if (broto <= years[indexYear][4].m5) {
            let result = (((((years[indexYear][0].m1 * years[indexYear][0].ahoz1)

                + (years[indexYear][1].m2 - (years[indexYear][0].plus1)) * years[indexYear][1].ahoz2

                + (years[indexYear][2].m3 - (years[indexYear][1].plus2)) * years[indexYear][2].ahoz3

                + (years[indexYear][3].m4 - (years[indexYear][2].plus3)) * years[indexYear][3].ahoz4))

                + (broto - years[indexYear][3].plus4) * years[indexYear][4].ahoz5));
            setResult(result.toFixed(0), sumPoingZ, cline, sumTroma, sumPoint);
            return;
        }
        //47%
        if (broto <= years[indexYear][5].m6) {
            let result = (((((years[indexYear][0].m1 * years[indexYear][0].ahoz1)

                + (years[indexYear][1].m2 - (years[indexYear][0].plus1)) * years[indexYear][1].ahoz2

                + (years[indexYear][2].m3 - (years[indexYear][1].plus2)) * years[indexYear][2].ahoz3

                + (years[indexYear][3].m4 - (years[indexYear][2].plus3)) * years[indexYear][3].ahoz4

                + (years[indexYear][4].m5 - (years[indexYear][3].plus4)) * years[indexYear][4].ahoz5))

                + (broto - years[indexYear][4].plus5) * years[indexYear][5].ahoz6));
            setResult(result.toFixed(0), sumPoingZ, cline, sumTroma, sumPoint);
            return;
        }
        //---50%
        if (broto > years[indexYear][5].m6) {
            let result = (((((years[indexYear][0].m1 * years[indexYear][0].ahoz1)
                + (years[indexYear][1].m2 - (years[indexYear][0].plus1)) * years[indexYear][1].ahoz2

                + (years[indexYear][2].m3 - (years[indexYear][1].plus2)) * years[indexYear][2].ahoz3

                + (years[indexYear][3].m4 - (years[indexYear][2].plus3)) * years[indexYear][3].ahoz4

                + (years[indexYear][4].m5 - (years[indexYear][3].plus4)) * years[indexYear][4].ahoz5

                + (years[indexYear][5].m6 - (years[indexYear][4].plus5)) * years[indexYear][5].ahoz6))

                + (broto - years[indexYear][5].plus6) * 0.50));
            setResult(result.toFixed(0), sumPoingZ, cline, sumTroma, sumPoint);
            return;
        }
    }

    return (
        <div className=''>
            <div>
                {
                    <div>

                        <div className='d-flex justify-content-center align-items-center flex-column  text-white'>
                            {
                                res && <LuPartyPopper color='yellow' size={50} />
                            }
                            {
                                !res && <div className=''></div>
                            }
                            <h5 className='mt-2 text-white'>{resultPerson}</h5>
                        </div>
                        {
                            <div>
                                <h5 className=' text-bg-primary text-white mt-3'>{props.name}</h5>
                                <h5 className=' text-bg-primary  text-white'>{props.phone}</h5>

                            </div>
                        }
                        <div className='' style={{ direction: 'rtl' }}>
                            {/* <button className="btn btn-primary mt-3 ">חזור</button> */}

                            {/* <button onClick={() => props.bakePage(3)} style={{ fontSize: '18px' }} className="btn btn-dark p-2  mt-3 border-3 border-success"> <RiArrowRightDoubleFill size={30} />
                            </button> */}
                            {
                                props.ifMrrey && <div className="">
                                    <h3 className=' text-primary'>המערכת זיהתה שהינך נשוי/ה</h3>
                                    <h4 className=' text-white'>על מנת לקבל תוצאה מדויקת</h4>
                                    <h6 className=' text-white'> העלה טפסי 106 גם לבן/בת הזוג</h6>
                                    <div className='d-flex justify-content-between' style={{ direction: 'rtl' }}>
                                        {/* <button className="btn btn-primary mt-3 ">חזור</button> */}
                                        <button onClick={() => props.isMerryBackPDF(resultSum)} className="btn btn-dark pe-4 ps-4 mt-3 border-3 border-success mt-3">  העלה 106 </button>
                                        {
                                            <button onClick={() => props.Recheck()} style={{ fontSize: '18px' }} className="btn btn-dark p-1  mt-3 border-3 border-success"> בדיקה לשנה נוספת  </button>
                                        }
                                    </div>
                                    <div className=' d-flex justify-content-end mt-4'>
                                        <button onClick={() => setclickFinish(true)} className="btn btn-dark border-danger " >סיום</button>
                                    </div>


                                </div>

                            }
                            {props.ifMrrey && isOpenMess && (
                                <div className="dialog-container p-4" style={{ direction: 'rtl' }}>
                                    <div className="dialog-content">
                                        <h3 className=' text-primary'>המערכת זיהתה שהינך נשוי/ה</h3>
                                        <h6>לבדיקה לבן/בת הזוג לתוצאה מדויקת, לחץ כאן</h6>
                                        <div className=' d-flex justify-content-between'>
                                            <button onClick={() => props.isMerryBackPDF(resultSum)} className="btn btn-dark pe-4 ps-4 border-3 border-success mt-3">לבדיקה </button>
                                            <button onClick={toggleBoxFinish} className="btn btn-dark pe-4 ps-4 border-3 border-success mt-3">  ביטול </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {clickFinish && (
                                <div className="dialog-container p-4" style={{ direction: 'rtl' }}>
                                    <div className="dialog-content">
                                        <h3 className=''>תודה, בקרוב אחד מנציגנו יחזור אלייך</h3>
                                        <div className=' d-flex justify-content-center'>
                                            <button onClick={backHomeFinish} className="btn btn-dark pe-4 ps-4 border-3 border-success mt-3">סיום </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {
                                (!props.ifMrrey && props.UseYearsLang < 6) && <div>
                                    <div className=' d-flex justify-content-center text-white row mt-2'>
                                        <h6 className='p-2'> אם ברצונך לבצע בדיקה לשנה נוספת  </h6>
                                        <button onClick={() => props.Recheck()} className="btn btn-dark p-2 col-3 border-3 border-success" > לחץ כאן </button>
                                    </div>
                                    <div className=' d-flex justify-content-end mt-2'>
                                        <button onClick={() => setclickFinish(true)} className="btn btn-dark border-danger " >סיום</button>
                                    </div>
                                </div>
                            }
                            {
                                (props.UseYearsLang >= 6) && <div>
                                    <h5 className='p-2 text-white'>לא נותרו שנים לבדוק. </h5>
                                    <div className=' d-flex justify-content-end mt-2'>
                                        <button onClick={() => setclickFinish(true)} className="btn btn-dark border-danger " >סיום</button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }

            </div>
        </div>

    )
}

export default ClacMasPage