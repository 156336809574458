import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as databaseRef } from 'firebase/database';
import '../loading.css';
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { FaIdCard } from "react-icons/fa";



const ImageUpload = (props) => {
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [imageLocal, setImageLocal] = useState('');
    const [loading, setLoad] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const storage = getStorage();
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Set the selected image to the result of FileReader
                setImageLocal(reader.result)
                setLoad(false);
            };
            reader.readAsDataURL(file); // Read the file as a Data URL
        }

        //props.showNextForId("");
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
            setLoad(true);
            if (ref(storage, `images/${e.target.files[0].name}`)) {
            }

            const storageRef = ref(storage, `images/${e.target.files[0].name}`);
            uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    setImageUrl(downloadURL);
                    sendImage(downloadURL);
                    const database = getDatabase();
                });
            }).catch((error) => {
                console.error('Error uploading image: ', error);
            }).catch((error) => {
                console.error('Error uploading image:2 ', error);
            });
        }

    };

    const sendImage = (url) => {
        props.takeUrlID(url)
    }
    const nextPage = () => {
        props.nextPageId();
    }

    return (
        <div className=''>
            <div className=''>
                <input type="file" accept="image/*" id="someInput" onChange={handleImageChange} />
                <label htmlFor="someInput" className="custom-file-input mb-4 mt-2">תעודת זהות + ספח</label>
            </div>
            {/* {
                imageUrl && <a className=' me-3 mt-3' style={{ textDecoration: 'none' }} href={imageUrl} target="_blank">צפייה</a>
            } */}
            {loading &&
                <div className="charging-circle-container">
                    <div className="charging-circle"></div>
                </div>
            }
            {
                (imageLocal != '') && <div>
                    <FaIdCard onClick={() => setIsOpen(true)} color='white' size={45} />
                </div>
            }
            <div className='d-flex justify-content-between' style={{ direction: 'rtl' }}>
                {/* <button className="btn btn-primary mt-3 ">חזור</button> */}

                <button onClick={() => props.bakePage(0)} style={{ fontSize: '18px' }} className="btn btn-dark p-2  mt-3 border-3 border-success"> <RiArrowRightDoubleFill size={30} />
                </button>
                {
                    (imageLocal != '') &&
                    <button onClick={() => nextPage()} style={{ fontSize: '18px' }} className="btn btn-dark p-2 anBack  mt-3 border-3 border-success"> המשך </button>

                }
                {isOpen && (
                    <div className="dialog-container " style={{ direction: 'rtl' }}>
                        <div className="dialog-content m-2">
                            <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={() => setIsOpen(false)}>X</button>
                            <img  src={imageLocal} alt="Uploaded" className='mt-1' height={400} width={300} />
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export default ImageUpload;