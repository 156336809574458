import React, { useEffect, useState } from 'react'
import { FaChevronDown } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import '../lid.css'
import { getDatabase } from "firebase/database";

export default function ItemLid(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPDF, setIsOpenPDF] = useState(false)
    const [isOpenASK, setIsOpenASK] = useState(false)
    const [isOpenClac, setIsOpenClac] = useState(false)
    const [clacIndex, setClacIndex] = useState("");
    const [clacIndexM, setClacIndexM] = useState("");
    const [isOpenClacM, setIsOpenClacM] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)
    const [SelectColor, setSelectColor] = useState('');
    const person = props.person;
    const [listPDF, setlistPDF] = useState([]);
    const [listASK, setlistASK] = useState([]);
    const [showHozh, setShowHozh] = useState(false);
    const isDisabled = true; // Set this to true/false based on your logic

    // Function to toggle the state of the box
    const toggleBox = () => {
        setIsOpen(!isOpen);
    };
    const clickPDF = (pdflist) => {
        //event.preventDefault();
        setlistPDF(pdflist);
        setIsOpenPDF(true);
    }
    const clickASK = (ansList) => {
        //event.preventDefault();
        setlistASK(ansList);
        setIsOpenASK(true);
    }

    const listQes = [
        "זכר או נקבה?",
        "מצב משפחתי?",
        "האם יש לך ילדים?",
        "כמה?",
        "כמה ילדים יש לך מתחת לגיל שנה?",
        "כמה ילדים יש לך מתחת לגיל 6?",
        "כמה ילדים יש לך מתחת לגיל 13?",
        "כמה ילדים יש לך מתחת לגיל 18?",
        "קיים ילד עם נטולי יכולת?",
        "האם יש לך תואר אקדמי?",
        "תרומות?",
        "סכום תרומות:",
        "קיים ביטוח חיים?",
        "אישור תושב ישוב מזכה?",
        "הכנסות מביטוח לאומי?",
        "אישור משיכת קופת גמל (טופס 806)?",
        "אישור ניכוי מס (טופס 867)?",
    ];


    const handleSelectColoer = (event) => {
        setSelectColor(event.target.value);
        props.updateColor(person.idkay, event.target.value)
    };

    const openClackShow = (index) => {
        setClacIndex(index)
        setIsOpenClac(true);
    }
    const openClackShowM = (index) => {
        setClacIndexM(index)
        setIsOpenClacM(true);
    }

    const handleClick = (phone) => {

        window.location = `tel:${phone}`;
    };

    const clickRemove = () => {
        setIsOpenRemove(true);
    }
    const clickRemoveItem = () => {
        const db = getDatabase();

        props.removeItem(person.idkay)
        setIsOpenRemove(false);
    }

    useEffect(() => {
        setSelectColor(props.color);
    }, [])
    const toggleBoxFinish = () => {
        setIsOpenASK(false);
        setIsOpenPDF(false)
        setIsOpenClac(false)
        setIsOpenClacM(false)
    };
    const toggleBoxFinishHoze = () => {
        setShowHozh(false);
    };

    const openNewTab = (url) => {
        // Replace 'https://example.com' with the URL you want to open
        window.open(url, '_blank');
    };


    return (

        <div className=' d-flex justify-content-center  ' style={{ direction: 'rtl' }}>
            <div className=" broderLog p-2 mt-4 bg-light col-11 col-xl-5   ">
                <div onClick={toggleBox} className=' d-flex justify-content-between'>
                    <div className='d-flex align-items-baseline'>
                        <h6 className='mt-1 me-2'>{person.name} - </h6>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }} className='mt-1 me-2'>{person.resPerson}</div>
                    </div>
                    <div className=' d-flex'>
                        <div style={{ backgroundColor: person.color }} className='circleState mt-1 ms-4'></div>
                        <FaChevronDown className='mt-1 ms-2' size={20} onClick={toggleBox} />
                    </div>
                </div>
                {
                    isOpen && <div>
                        <hr className='hrBox'></hr>
                        <h6 className='mt-1 me-2'> - {person.date}</h6>
                        <div className=' d-flex justify-content-between'>
                            {(person.resPerson != "חסר פרטים") && <a className='mt-1 me-2' style={{ textDecoration: 'none', color: 'blue' }} target="_blank" href={person.id}>תעודת זהות</a>}
                            <div className='mt-1'>
                                <button className=' me-2 button-link' onClick={() => setShowHozh(true)}>
                                    הסכם החברה</button>
                            </div>
                            <div className=''>
                                <button style={{ background: "none", border: "none", textDecoration: "none" }} className=' d-flex text-dark' href='#'>
                                    <div onClick={() => handleClick(person.phone)} className='mt-1 ms-2 not-phone'>{person.phone}</div>
                                    <FaPhoneAlt onClick={() => handleClick(person.phone)} size={20} className='mt-1 ms-2' />
                                </button>
                            </div>
                        </div>
                        {
                            (person.resPerson !== "חסר פרטים" && person.details) && (
                                <div>
                                    {[...person.details].map((detail, i) => (
                                        <div key={i}>
                                            {
                                                !(person.resPerson == "שבח") ?
                                                <h4 className='d-flex justify-content-center mt-2'> שנת בדיקה: {detail.year}</h4> :
                                                <h4 className='d-flex justify-content-center mt-2'> שומת שבח</h4>
                                            }
                                            {
                                                detail.sumM != null && <h6 className='mt-2 text-center text-secondary'>{person.name}</h6>
                                            }
                                            <div className='d-flex justify-content-center'>
                                                <div className=' '>
                                                    {detail.sahclin !== "null" && (
                                                        <div>
                                                            {
                                                                !(person.resPerson == "שבח")
                                                                && <div>
                                                                    <h6> סך משכורת -  {detail.sahclin.split('|')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                                    <h6> ניכוי למס -  {detail.sahclin.split('|')[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                                    <h6> הפקדות העובד - {detail.sahclin.split('|')[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                                </div>
                                                            }

                                                            <div className=' row'>
                                                                {
                                                                    detail.pdf != "" && (<button style={{ textDecoration: "none", color: 'blue' }} className='mt-1 ms-2 button-link' onClick={() => clickPDF(detail.pdf)} >טפסי 106</button>)
                                                                }
                                                                {
                                                                    detail.tromaUrl != "" && (<button style={{ textDecoration: "none", color: 'blue' }} className='mt-1 ms-2 button-link' onClick={() => openNewTab(detail.tromaUrl)}> {person.resPerson == "שבח" ? " טופס שבח" : " טופס תרומה"}</button>)
                                                                }
                                                                {
                                                                    detail.lumiUrl != "" && (<button style={{ textDecoration: "none", color: 'blue' }} className='mt-1 ms-2 button-link' onClick={() => openNewTab(detail.lumiUrl)} >טופס ביטוח לאומי</button>)
                                                                }
                                                            </div>

                                                            {
                                                                !detail.namem == "" && (<div className={(detail.sum) < 0 ? 'align-items-baseline d-flex text-primary' : 'align-items-baseline d-flex text-danger'} style={{ direction: 'rtl' }}>
                                                                    <h6 className='mt-1'>{(detail.sum) < 0 ? `סך החזרים ל${person.name}:` : ` סך חיוב ל${person.name}:`} </h6>
                                                                    <h5 className='me-1' style={{ direction: 'ltr' }}>{parseInt((detail.sum)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                                                                </div>
                                                                )
                                                            }

                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                            {
                                                detail.dataClac != "" && <div className='d-flex justify-content-center mt-2 ms-2'>
                                                    <button onClick={() => openClackShow(i)} className='mt-1 me-2 custom-button'>דרך החישוב</button>
                                                </div>
                                            }
                                            {
                                                !detail.namem == "" && (<div className='d-flex justify-content-center'>
                                                    <hr className='hrBoxMrry'></hr>
                                                </div>)
                                            }



                                            {
                                                detail.ans != "" && <div className='d-flex justify-content-center mt-2 ms-2'>
                                                    <button onClick={() => clickASK(detail.ans)} className='mt-1 me-2 custom-button'>תשובות הלקוח לשאלון </button>
                                                </div>
                                            }
                                            {
                                                (detail.ans != "" && detail.sumM == null) && <div className='d-flex justify-content-center align-items-baseline ms-2'>
                                                    {(
                                                        <div className={(detail.sum) < 0 ? 'align-items-baseline d-flex text-primary  broderSum mt-2' : 'align-items-baseline d-flex text-danger  broderSum mt-2'} style={{ direction: 'rtl' }}>
                                                            <h6 className='mt-1 me-2'> {(detail.sum) < 0 ? `סך החזרים ל${person.name}:` : ` סך חיוב ל${person.name}:`} </h6>
                                                            <h5 className='me-1 mt-2 ms-2' style={{ direction: 'ltr' }}>{parseInt((detail.sum)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                            {
                                                detail.sumM != null && <div>
                                                    <h6 className='mt-2 text-center text-secondary'>{detail.namem}</h6>
                                                    <div className='d-flex justify-content-center mt-2'>
                                                        <div className='d-flex align-items-baseline '>
                                                            {detail.sahclinM !== "null" && (
                                                                <div>
                                                                    <h6> סך משכורת -  {detail.sahclinM.split('|')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                                    <h6> ניכוי למס -  {detail.sahclinM.split('|')[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                                    <h6> הפקדות העובד -  {detail.sahclinM.split('|')[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                                    <div className='row'>
                                                                        {
                                                                            detail.pdfM != "" && (<button style={{ textDecoration: "none", color: 'blue' }} className='mt-1 ms-2 button-link' onClick={() => clickPDF(detail.pdfM)} >טפסי 106</button>)
                                                                        }
                                                                        {
                                                                            (detail.lumiUrlM != "") && (<button style={{ textDecoration: "none", color: 'blue' }} className='mt-1 ms-2 button-link' onClick={() => openNewTab(detail.lumiUrlM)} >טופס ביטוח לאומי</button>)
                                                                        }
                                                                    </div>
                                                                    <div className={(detail.sumM) < 0 ? 'align-items-baseline d-flex text-primary' : 'align-items-baseline d-flex text-danger'} style={{ direction: 'rtl' }}>
                                                                        <h6 className='mt-1'>{(detail.sumM) < 0 ? `סך החזרים ל${detail.namem}:` : `סך חיוב ל${detail.namem}:`}</h6>
                                                                        <h5 className='me-1' style={{ direction: 'ltr' }}>{parseInt((detail.sumM)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                                                                    </div>
                                                                </div>

                                                            )}

                                                        </div>
                                                    </div>
                                                    {
                                                        detail.dataClacM != null && <div className='d-flex justify-content-center mt-2 ms-2'>
                                                            <button onClick={() => openClackShowM(i)} className='mt-1 me-2 custom-button'>דרך החישוב</button>
                                                        </div>
                                                    }
                                                    <div className='d-flex justify-content-center mt-2 ms-2'>
                                                        <button onClick={() => clickASK(detail.ans)} className='mt-1 me-2 custom-button'>תשובות הלקוח לשאלון </button>
                                                    </div>
                                                    <div className='d-flex justify-content-center align-items-baseline ms-2'>
                                                        {(
                                                            <div className={(parseInt(detail.sumM) + parseInt(detail.sum)) < 0 ? 'align-items-baseline text-primary d-flex broderSum mt-2' : 'align-items-baseline text-danger d-flex broderSum mt-2'} style={{ direction: 'rtl' }}>
                                                                <h6 className='mt-1 me-2 '>{(parseInt(detail.sumM) + parseInt(detail.sum)) < 0 ? `סך החזרים לבני הזוג לשנת ${detail.year}:` : `סך חיובים לבני הזוג לשנת ${detail.year}:`}</h6>
                                                                <h5 className='me-1 mt-2 ms-2 ' style={{ direction: 'ltr' }}>{
                                                                    (parseInt(detail.sumM) + parseInt(detail.sum)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                                                            </div>
                                                        )}
                                                    </div>

                                                </div>
                                            }
                                            <div className='d-flex justify-content-center'>
                                                <hr style={{ width: '80%' }} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                        <div>
                            <select className=' pe-3 ps-3 mt-2 selcet-tepy mb-2 myText p-1' value={SelectColor} onChange={handleSelectColoer}>
                                <option className='text-dark' value="red" >מצב טיפול</option>
                                <option value='red'>לפני טיפול</option>
                                <option value="yellow">בטיפול</option>
                                <option value="green">סיום</option>
                            </select>
                        </div>
                    </div>
                }
            </div>

            {
                showHozh && (
                    <div className="dialog-container" style={{ direction: 'rtl' }}>
                        <div className="dialog-content m-3 col-xl-6" >
                            <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={toggleBoxFinishHoze}>X</button>
                            <h5 className='p-2  text-center'>הסכם החברה</h5>
                            <div className='  mt-2'>
                                <div className='' style={{ overflowY: 'scroll', height: '300px', border: '1px solid #ccc', padding: '10px' }}>
                                    <p>מושג החוזה מופיע כבר בתנ"ך: "כרתנו ברית את מוות ועם שאוֹל עשינו חוזה"[2].

                                        המג'לה, קובץ החוקים האזרחי של האימפריה העות'מאנית, שהיווה את המשפט הנוהג בארץ ישראל בעת שלטון העות'מאנים, לא דנה במושג החוזה. הוראות המג'לה הנוגעות לחוזים היו מעטות ומפוזרות, ודנו בסוגים מסוימים של חוזים, ובסיטואציות מסוימות הנוגעות לחוזים, אך לא הגדירו בצורה מקיפה את מושג החוזה, דרכי כריתתו, הפגמים בכריתתו, והסעדים המוקנים במקרה של הפרתו. המג'לה עסקה בסוגיות הנוגעות למכר, שכירות, ערבות והמחאה. כן עסקה המג'לה בסוגיות כגון סוגיית הכפייה בדיני החוזים. חוק הפרוצדורה האזרחית העות'מאני הוסיף הוראות נוספות, כגון הוראת סעיף 64 לפיו חוזה שממרה את פי החוק או שאינו עולה בקנה אחד עם ביטחון הכלל או עם המוסר הציבורי הוא בטל ומבוטל, סעיף 80 לפיו סוגים שונים של חוזים יש להוכיחם אך ורק בכתב, או סעיף 111 שדן בנושא של פיצויים מוסכמים במקרה של הפרת חוזה.

                                        עם תחילתו של המנדט הבריטי אושר תוקפם של החוקים העות'מאניים על פי דבר המלך במועצה על ארץ ישראל משנת 1922, אולם מכיוון שהמשפט העות'מאני היה כה מפגר ביחס לחוזה ומהותו, הוזרמו למשפט המקומי דיני החוזים האנגלים מכוח סעיף 46 בדבר המלך לפיו במקום בו אין הוראה מפורשת בחוק העות'מאני יש לנהוג לפי המשפט האנגלי.

                                        המשפט האנגלי הכיל מערכת ברורה ומקיפה אשר הגדירה את החוזה באופן המתאים למשפטה של מדינה מודרנית, ומבוססת על מסורת רבת שנים של המשפט המקובל. עם זאת, השילוב של הדין העות'מאני והדין האנגלי יצר יציר כלאיים מוזר, ולעיתים בלתי אפשרי. כך, למשל, לא הכיר הדין העות'מאני בסעד האכיפה שהוא סעד מהותי בדין האנגלי, ולא הבחין בין פיצויים מוסכמים לאלו הנקבעים על ידי בית המשפט. גם ניסיונות להסדרה חקיקתית של נושאים אלו נתקלו בקשיים ביישומם בפועל בבתי המשפט. הבדל ממשי נוסף בין הדין העות'מאני והדין האנגלי היה כי הדין האנגלי דרש תמורה כבסיס לתוקף החוזה. דרישה זו היא ייחודית למשפט המקובל ואינה נמצאת בשיטות משפט אחרות. כך שנוצר מצב בו החוזים אליהם התייחסה המג'לה - חוזי שכירות, ערבות וכו', לא דרשו תמורה כתנאי לתוקפם, בעוד שחוזה מכל סוג אחר דרש תמורה. דרישת התמורה היא דרישה מלאכותית, שהביאה לפורמליזם מיותר בדיני החוזים במשפט האנגלי, והמצב שנוצר סיבך מאוד את דיני החוזים בארץ ישראל.

                                        לאחר שקמה מדינת ישראל, קבעה פקודת סדרי השלטון והמשפט כי המשפט שחל עד אז, ימשיך לחול, אך ניתקה את הקשר שבין התפתחויות פסיקתיות וחקיקתיות בבריטניה ובין המשפט הישראלי. אל השעטנז המשפטי הקיים נוסף עתה רובד של פסיקה ישראלית עצמאית, שהחלה ביצירת משפט חוזי ישראלי עצמאי. עם סוף שנות ה-60 החל המחוקק מסדיר את דיני החוזים, בחקיקה ישראלית מקורית, שבין מקורות ההשראה לה היו גם המשפט העברי וגם שיטות המשפט האזרחי ובמיוחד ה-BGB, הקודקס האזרחי הגרמני. בין חקיקת חוקים העוסקים בחוזים ספציפיים כגון חוזה הביטוח, החוזה האחיד, חוזה השכירות, חוזה הקבלנות וחוזה המכר, נחקקו שני חוקי החוזים הכלליים והעיקריים והם חוק החוזים (תרופות בשל הפרת חוזה), תש"ל-1970, וחוק החוזים (חלק כללי), תשל"ג-1973.

                                        סעיף 1 לחוק החוזים (חלק כללי) הגדיר, לראשונה במשפט הישראלי, את מהות החוזה, בקובעו "חוזה נכרת בדרך של הצעה וקיבול לפי חוק זה". לפי הגדרה זו חוזה הוא תוצר של מפגש רצונות של הצדדים, תהליך חברתי בעיקרו המסדיר את הקשר בין בני האדם על בסיס של הסכמה והבנה הדדית, ומבטא את רצונם ההדדי של הצדדים בקשר ביניהם.</p>
                                    <div className=' d-flex '>
                                        <label className='select text-primary checkbox-container p-1 col-xl-6'>
                                            <input
                                                className='ms-2'
                                                type="checkbox"
                                                checked={true}
                                                readOnly={true}
                                            />
                                            קראתי את תנאי השימוש
                                        </label>
                                        <h5 className='ms-1 me-3 mt-2'>{person.name}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                isOpenASK && (
                    <div className="dialog-container pe-2 ps-2" style={{ direction: 'rtl' }}>

                        <div className="dialog-content">
                            <div className=' d-flex justify-content-start'>
                                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} onClick={toggleBoxFinish} className='text-danger'>X</button>
                            </div>
                            <div className='p-4'>
                                {[...listASK].map((f, i) => (
                                    listASK[i] != "--" && <div key={i} className=' d-flex row'>{
                                        (i == 14 && listASK[i] != "לא" && listASK[i] != "כן") ? <h6> {listQes[i]} הסכום: {(listASK[i]).toString().split("|")[0] != "" ? (listASK[i]).toString().split("|")[0] : 0}
                                            , המס: {(listASK[i]).toString().split("|")[1] != "" ? (listASK[i]).toString().split("|")[1] : 0} </h6> : <h6> {listQes[i]}  {listASK[i]}</h6>
                                    }</div>
                                ))}

                            </div>
                            <div>
                                {person.details[0].namem != null ? (
                                    <div>
                                        <h6>--------</h6>
                                        <h6>הזוג:</h6>
                                        <h6>ביטוח חיים? {person.details[0].safeLifeM}</h6>
                                        {person.details[0].enterMesM != "כן" && person.details[0].enterMesM != "לא" ? <h6>
                                            הכנסות מביטוח לאומי? הסכום: {person.details[0].enterMesM.toString().split("|")[0] != "" ? person.details[0].enterMesM.toString().split("|")[0] : 0}, המס: {person.details[0].enterMesM.toString().split("|")[1] != "" ? person.details[0].enterMesM.toString().split("|")[1] : 0}
                                        </h6> : <h6>  הכנסות מביטוח לאומי? {person.details[0].enterMesM}</h6>}
                                        <h6>אישור משיכת קופת גמל? {person.details[0].koptGemlM}</h6>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                    </div>
                )
            }

            {
                isOpenClac && (
                    <div className="dialog-container pe-2 ps-2" style={{ direction: 'rtl' }}>

                        <div className="dialog-content">
                            <div className=' d-flex justify-content-start'>
                                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} onClick={toggleBoxFinish} className='text-danger'>X</button>
                            </div>
                            <div className='p-4'>
                                {[...person.details].map((detail, i) => (

                                    i == clacIndex && <div style={{ direction: "ltr" }}>
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClac.split("|")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :תוצאת המחשבון </h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClac.split("|")[5]} :סך נקודות זיכוי</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClac.split("|")[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :סך החזרי נקודות זיכוי</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClac.split("|")[3].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :זיכוי מתרומה</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClac.split("|")[4].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :(אחרי 35 אחוז)045</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClac.split("|")[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :המס שחייב בפועל</h6>
                                    </div>


                                ))}
                            </div>
                        </div>

                    </div>
                )
            }
            {
                isOpenClacM && (
                    <div className="dialog-container pe-2 ps-2" style={{ direction: 'rtl' }}>

                        <div className="dialog-content">
                            <div className=' d-flex justify-content-start'>
                                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} onClick={toggleBoxFinish} className='text-danger'>X</button>
                            </div>
                            <div className='p-4'>
                                {[...person.details].map((detail, i) => (
                                    i == clacIndexM && <div style={{ direction: "ltr" }}>
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClacM.split("|")[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :תוצאת המחשבון</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClacM.split("|")[5]} :סך נקודות זיכוי</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClacM.split("|")[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :סך החזרי נקודות זיכוי</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClacM.split("|")[4].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :(אחרי 35 אחוז)045</h6>
                                        <hr />
                                        <h6 className=' d-flex justify-content-end'> {detail.dataClacM.split("|")[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} :המס שחייב בפועל</h6>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                )
            }

            {
                isOpenPDF && (
                    <div className="dialog-container" style={{ direction: 'rtl' }}>

                        <div className="dialog-content">
                            <div className=' d-flex justify-content-start'>
                                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} onClick={toggleBoxFinish} className='text-danger'>X</button>
                            </div>
                            <div className='p-4'>
                                {[...listPDF].map((f, i) => (
                                    <div key={i} className=' d-flex row'>
                                        <a style={{ textDecoration: "none" }} className='mt-2' href={listPDF[i]} target="_blank"> 106 טופס - {i + 1} </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            }


            {
                isOpenRemove && (
                    <div className="dialog-container" style={{ direction: 'rtl' }}>

                        <div className="dialog-content">
                            <div className=' d-flex justify-content-start'>
                                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} onClick={toggleBoxFinish} className='text-danger'>X</button>
                            </div>
                            <div className='p-3'>
                                <h5>בטוח שברצונך למחוק ?</h5>
                                <div className=' d-flex justify-content-between mt-4 ms-2'>
                                    <button onClick={clickRemoveItem} className='mt-1 me-2 custom-button'>כן</button>
                                    <button onClick={toggleBoxFinish} className='mt-1 me-2 custom-button'>בטל</button>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
